<template>
  <div class="cd__prg-overview-wrapper">
    <b-row>
      <b-col cols="5">
        <div class="cd__prg-overview__banner">
          <img :src="getBackground" :alt="getSelectedCourse.title" />
          <div
            v-if="cStep === 3 || cStep === 4"
            class="cd__prg-overview__progress-wrapper"
          >
            <ProgressBar :value="progressValue" :max="max"></ProgressBar>
          </div>
        </div>
      </b-col>
      <b-col>
        <p class="cd-sec__category">
          <span class="org c-pointer" @click="onOrgClick">{{
            getSelectedCourse.org ? getSelectedCourse.org : "Organisation"
          }}</span>
          <span>
            {{ $t(`data.pacing.${getSelectedCourse.pacing}`) }}
          </span>
          <span class="separator"></span>
          <span>
            {{
              getSelectedCourse.language
                ? $t(`data.language.${getSelectedCourse.language}`)
                : $t(`data.language.en`)
            }}
          </span>
        </p>
        <h3 :title="getSelectedCourse.title">
          {{ getSelectedCourse.title }}
        </h3>
        <p class="cd-sec__des">
          {{ getSelectedCourse.short_description }}
        </p>
        <p class="mb-0 cd-cta__content" v-if="cStep !== 1">
          <img
            src="@/assets/images/icons/calendar-icon.svg"
            :class="{ 'ml-2': language === 'ar', 'mr-2': language !== 'ar' }"
          />

          {{
            hasFutureEnrollmentDate
              ? $t("course.learning_programme.begin.title")
              : $t("course.learning_programme.start.title")
          }}
          <strong>{{
            getSelectedCourse.course_starts_at | formatDate("MMM Do YYYY")
          }}</strong>
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";

export default {
  mixins: [redirectToLearningSpace],
  props: {
    getSelectedCourse: {
      type: Object,
      required: true
    },
    cStep: {
      type: Number,
      required: true
    },
    hasFutureEnrollmentDate: {
      type: Boolean,
      required: true
    },
    progressValue: {
      type: Number,
      required: true
    },
    currentModule: {
      type: Number,
      required: true
    },
    totalModule: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(["language"]),
    getBackground() {
      return this.getSelectedCourse.image_url
        ? this.getSelectedCourse.image_url
        : require("@/assets/images/illustration/course-illustration.svg");
    }
  },
  methods: {
    onOrgClick() {
      this.navigateToLearningSpace(this.getSelectedCourse.org);
    }
  }
};
</script>
<style lang="scss">
.cd__prg-overview-wrapper {
  padding: 30px 0 40px 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 40px;
  color: $brand-default-text;

  .cd__prg-overview__banner {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(160, 164, 168, 0) 0%,
      #757575 100%
    );
    border-radius: 4px;
    height: 100%;

    img {
      width: 100%;
    }

    .cd__prg-overview__progress-wrapper {
      position: absolute;
      bottom: 14px;
      width: calc(100% - 24px);
      margin: 0 12px;
    }
  }

  .cd-sec__category {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 11px;

    .org {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      border-right: 2px solid;
      padding-right: 15px;
      margin-right: 15px;
    }

    .separator {
      &::after {
        content: " ";
        display: inline-block;
        background: $brand-default-text;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        margin: 2px 8px;
      }
    }
  }

  h3 {
    color: $brand-secondary-text;
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
    word-break: break-word;
    margin-bottom: 20px;
  }

  .cd-sec__des {
    margin-bottom: 20px;
    word-break: break-word;
  }
}
@media screen and (max-width: 768px) {
  .cd__prg-overview-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .cd__prg-overview-wrapper h3,
  h2 {
    color: $brand-secondary-text;
    @include h5;
    margin-bottom: 24px;
    margin-top: 65px;
  }
  .response h3,
  h2 {
    color: $brand-secondary-text;
    @include h5;
    margin-bottom: 24px;
    margin-top: 65px;
  }
  .teacher-image img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
  .teacher h3 {
    @include subtitle-regular;
    display: flex;
    align-items: center;
    color: $brand-neutral-900;
  }
}
</style>
